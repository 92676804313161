import moment from 'moment';

if ($(".js-countdown").length) {
  const $countdown = $(".js-countdown"),
    $days = $(".js-countdown__days"),
    $hours = $(".js-countdown__hours"),
    $minutes = $(".js-countdown__minutes"),
    $seconds = $(".js-countdown__seconds");

  const eventDateProvided = $countdown.attr("data-end-date");
  const convertEventDate = moment
    .utc(eventDateProvided)
    .format("YYYY-MM-DD HH:mm:ss");
  const eventTime = moment(convertEventDate, "YYYY-MM-DD HH:mm:ss");
  const interval = 1000;

  setInterval(function() {
    let currentTime = moment(),
      diffDays = eventTime.diff(currentTime, "days"),
      diffHours = eventTime.diff(currentTime, "hours"),
      diffMinutes = eventTime.diff(currentTime, "minutes"),
      diffSeconds = eventTime.diff(currentTime, "seconds");

    let d = diffDays,
      h = diffHours - diffDays * 24,
      m = diffMinutes - diffHours * 60,
      s = diffSeconds - diffMinutes * 60;

    d = $.trim(d).length === 1 ? "0" + d : d;
    h = $.trim(h).length === 1 ? "0" + h : h;
    m = $.trim(m).length === 1 ? "0" + m : m;
    s = $.trim(s).length === 1 ? "0" + s : s;

    $days.text(d);
    $hours.text(h);
    $minutes.text(m);
    $seconds.text(s);
  }, interval);
}
