import Vue from "vue/dist/vue.esm";

if ($("body.carts.show").length > 0) {
  new Vue({
    el: "#carts-order",
    data: () => {
      return {
        activeTab: "cart"
      };
    },
    mounted() {
      GreenCome.retrigger("#carts-order")
    }
  });
}
