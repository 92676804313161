/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import * as Turbolinks from "turbolinks";
// Turbolinks.start();

import "../initializers/vue-layout";
import "../initializers/vue-products-list";
import "../initializers/vue-products-page";
import "../initializers/vue-user";
import "../initializers/vue-carts";

import "../initializers/clipboard";
import "../initializers/cookie-banner";
import "../initializers/dynamic-scroller";
import "../initializers/inputs-filled-checker";
import "../initializers/moment";
import "../initializers/radio-input-tabs";
import "../initializers/scrollytelling-animation";
import "../initializers/slick-carousel";
import "../initializers/tags-container";
import "../initializers/video-modal";
import "select2";

import "normalize.css";
import "animate.css";
import "select2/dist/css/select2.min.css";
import "../stylesheets/application.scss";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// require.context("../images", true);
// const imagePath = name => images(name, true);

// Uncomment to load svg sprite
const sprites = require.context("../icons", false);
sprites.keys().forEach(sprites);

// make visible .avoid-style-flash elements
setTimeout(function() {
  $(".avoid-style-flash").each(function() {
    $(this).css("visibility", "visible");
  });
}, 500);
