const $scroller = $(".js-dynamic-scroller");
const $scrollerBody = $(".js-dynamic-scroller-body");
const $scrollNavPrev = $(".js-dynamic-scroller-nav.is-prev");
const $scrollNavNext = $(".js-dynamic-scroller-nav.is-next");
const $scrollerLastElement = $scrollerBody.children().last();
let scrollPos = 0;

function updateScroller() {
  let totalElementsWidth = 0;

  $scroller.removeClass("is-scroller is-scrolled");

  $scrollerBody.children().each(function() {
    totalElementsWidth += $(this).outerWidth();
  });

  if (totalElementsWidth >= $scrollerBody.outerWidth()) {
    $scroller.addClass("is-scroller");
    $scrollerBody.scrollLeft( $('.tab.tab--product.is-active').position().left - 50 );
  } else {
    $scroller.removeClass("is-scroller");
  }
}

$scrollNavPrev.on("click", function() {
  scrollPos -= $scrollerBody.innerWidth();
  if (scrollPos <= 0) {
    scrollPos = 0;
    $scroller.removeClass("is-scrolled");
  }
  $scrollerBody.scrollLeft(scrollPos);
  $scroller.removeClass("is-scrolled-end");
});

$scrollNavNext.on("click", function() {
  scrollPos += $scrollerBody.innerWidth();
  $scrollerBody.scrollLeft(scrollPos);
  $scroller.addClass("is-scrolled");

  const lastElementPos = $scrollerLastElement.position().left;
  if (lastElementPos < $scrollerBody.innerWidth()) {
    $scroller.addClass("is-scrolled-end");
  }
});

$scrollerBody.scroll(function() {
  scrollPos = $scrollerBody.scrollLeft();
  $scroller.addClass("is-scrolled");

  const lastElementPos = $scrollerLastElement.position().left;
  if (lastElementPos < $scrollerBody.innerWidth()) {
    $scroller.addClass("is-scrolled-end");
  } else if ($scrollerBody.scrollLeft() == 0) {
    $scroller.removeClass("is-scrolled");
  } else {
    $scroller.removeClass("is-scrolled-end");
  }
});

$(document).ready(function() {
  updateScroller();
});

$(window).resize(function() {
  updateScroller();
});
