if ($(".js-check-inputs-filled").length > 0) {
  const $inputs = $(".js-check-inputs-filled .input").filter(function() {
    return $(this).attr("id") !== "mobile-passcode";
  });
  const $submitButton = $(".button__submit");
  let inputsFilled;

  function checkInputsFilled() {
    $inputs.each(function() {
      inputsFilled = false;
      if ($(this).val().length <= 0) {
        return false;
      } else {
        inputsFilled = true;
      }
    });

    if (inputsFilled) {
      $submitButton.addClass("is-valid");
      $submitButton.unbind("click");
    } else {
      $submitButton.removeClass("is-valid");
      $submitButton.click(function(event) {
        event.preventDefault();
      });
    }
  }

  $inputs.keyup(function() {
    checkInputsFilled();
  });
}
