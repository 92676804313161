const $body = document.querySelector("body");
let scrollPosition = 0;

const bodyScrollLock = {
  enable() {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = "hidden";
    $body.style.position = "fixed";
    $body.style.top = `-${scrollPosition}px`;
    $body.classList.add("js-lock-screen");
  },
  disable() {
    $body.style.removeProperty("overflow");
    $body.style.removeProperty("position");
    $body.style.removeProperty("top");
    $body.classList.remove("js-lock-screen");
    window.scrollTo(0, scrollPosition);
  },
  toggle() {
    const isLocked = $body.classList.contains("js-lock-screen");
    if (isLocked) {
      bodyScrollLock.disable();
      return;
    }
    bodyScrollLock.enable();
  }
};

export default bodyScrollLock;
