import * as Cookies from "js-cookie";

const AFFILIATE = "is_affiliates_stripe_closed";
const BONUS = "is_bonus_banner_closed";

const mainHeader = document.querySelector(".main-header");

function initCookie() {
  if (Cookies.get(BONUS)) {
    $("#index-bonus-banner").addClass("hidden");
  }
  if (Cookies.get(AFFILIATE)) {
    $("#affiliates-stripe").addClass("hidden");
    document.documentElement.style.setProperty(
      "--main-header",
      `${mainHeader.offsetHeight}px`
    );
  }
}

$(document).on("click", "#js-cookie-affiliates-stripe", () => {
  Cookies.set(AFFILIATE, "yes", {
    // expires: Number(process.env.COOKIE_EXPIRES) || 1
  });
  $("#affiliates-stripe").addClass("hidden");
  document.documentElement.style.setProperty(
    "--main-header",
    `${mainHeader.offsetHeight}px`
  );
});

$(document).on("click", "#js-cookie-bonus-banner", () => {
  Cookies.set(BONUS, "yes", {
    expires: Number(process.env.COOKIE_EXPIRES) || 1
  });
  $("#index-bonus-banner").addClass("hidden");
});

initCookie();
