import Vue from "vue/dist/vue.esm";

import MarqueeText from "vue-marquee-text-component";

import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareLine from "vue-goodshare/src/providers/Line.vue";

import bodyScrollLock from "../modules/scroll-lock";

const OFFSET = 60;

new Vue({
  el: "#main-header",
  components: {
    MarqueeText,
    VueGoodshareFacebook,
    VueGoodshareLine
  },
  data: () => {
    return {
      lastScrollPosition: 0,
      scrollValue: 0,
      isNavExpanded: true,
      isNewsDropdownLunched: false,
      isSideMenuLunched: false,
      isSearchActive: false,
      isSearchFocus: false,
      marqueePause: false,
      mobileTabActive: "products",
      prevScrollPos: 0,
      searchQuery: ''
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.handleNavScroll);
    GreenCome.retrigger("#main-header");
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleNavScroll);
  },
  methods: {
    checkSearchActive(){
      this.isSearchActive = this.searchQuery && this.searchQuery.trim() !== '';
    },
    handleNavScroll() {
      if (window.pageYOffset < 0) return;
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) return;
      if (window.pageYOffset >= 300) {
        $(".page_corner").fadeIn();
        this.isNewsDropdownLunched = false;
      } else {
        $(".page_corner").fadeOut();
        if ($("body.products.show").length < 0) {
          $("#sns-widget").fadeOut();
        }
      }
      if ($("body.products.show").length) {
        const contentPosition = $(".product__content").offset().top;
        const footerPosition = $(".product__footer").offset().top;
        if (
          window.pageYOffset > contentPosition &&
          window.pageYOffset < footerPosition
        ) {
          this.isNavExpanded = false;
          return;
        }
      }
      this.isNavExpanded = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
    bodyScrollLock() {
      if (this.isSideMenuLunched) {
        bodyScrollLock.enable();
        return;
      }
      if (this.isSearchActive && $(window).width() < 1280) {
        bodyScrollLock.enable();
        return;
      }
      if (!this.isSearchFocus) {
        bodyScrollLock.disable();
      }
    },
    pauseMarquee() {
      if ($(window).width() > 1280) {
        this.marqueePause = true;
      }
    },
    resumeMarquee() {
      this.marqueePause = false;
    },
    showPopover(target, hide_target) {
      if ($(window).width() < 1280) {
        const $hideTarget = $("#" + hide_target);
        if ($hideTarget.length > 0 && $hideTarget.is(":visible")) {
          $hideTarget.slideToggle();
        }
        const $popover = $("#" + target);
        $popover.slideToggle();
      }
    },
    toggleSharePanel() {
      if ($(window).width() < 1280) {
        $("#affiliates-drawer").fadeToggle();
        $(".js-toggle-affiliates-drawer").toggleClass("is-active");
        bodyScrollLock.toggle();
      } else {
        $(".affiliates-stripe #share-panel").fadeToggle();
        bodyScrollLock.toggle();
      }
    },
    updateAffiliatesDrawer() {
      $("#affiliates-drawer").fadeOut();
      $("#share-panel").fadeOut();
      $(".js-toggle-affiliates-drawer").removeClass("is-active");
      bodyScrollLock.disable();
    }
  }
});

new Vue({
  el: "#main-footer",
  data: () => {
    return {
      activeAccordion: ""
    };
  },
  mounted() {
    GreenCome.retrigger("#main-footer");
  },
  methods: {
    updateAccordion(target) {
      if (this.activeAccordion == "accordion-" + target) {
        this.activeAccordion = "";
      } else {
        this.activeAccordion = "accordion-" + target;
      }
    }
  }
});

new Vue({
  el: "#footer-nav",
  components: {
    VueGoodshareFacebook,
    VueGoodshareLine
  },
  data: () => {
    return {
      isBonusActive: false
    };
  },
  methods: {
    backToTop() {
      // window.scrollTo({ top: 0, behavior: "smooth" });
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: 0
          },
          800
        );
    },
    showSNSWidget() {
      $("#sns-widget").fadeToggle();
      $("#sns-widget").toggleClass("is-active");
      $(".js-toggle-sns-widget").toggleClass("is-active");
      if ($(window).width() < 1280) {
        bodyScrollLock.toggle();
      }
    },
    showAffiliatesDrawer() {
      if ($(window).width() < 1280) {
        $("#affiliates-drawer").fadeToggle();
        $(".js-toggle-affiliates-drawer").toggleClass("is-active");
        bodyScrollLock.toggle();
      }
    },
    updateBonusActive() {
      this.isBonusActive = !this.isBonusActive;
      if(this.isBonusActive){
        bodyScrollLock.enable();
      }
      else{
        bodyScrollLock.disable();
      }
    }
  },
  mounted() {
    GreenCome.retrigger("#footer-nav");
  }
});

new Vue({
  el: "#sns-widget",
  components: {
    VueGoodshareFacebook,
    VueGoodshareLine
  },
  methods: {
    showSNSWidget() {
      $("#sns-widget").fadeToggle();
      $("#sns-widget").toggleClass("is-active");
      $(".js-toggle-sns-widget").toggleClass("is-active");

      if ($(window).width() < 1280) {
        bodyScrollLock.toggle();
      }

      GreenCome.retrigger("#sns-widget");
    }
  }
});

if ($(".account-affiliates").length > 0) {
  new Vue({
    el: "#share-panel-inset",
    components: {
      VueGoodshareFacebook,
      VueGoodshareLine
    }
  });
}

new Vue({
  el: "#page-corner",
  components: {
    VueGoodshareFacebook,
    VueGoodshareLine
  },
  methods: {
    toggleSharePanel() {
      $("#page-corner #share-panel").fadeToggle();
    },
    showSNSWidget() {
      $("#sns-widget").fadeToggle();
      $("#sns-widget").toggleClass("is-active");
      $(".js-toggle-sns-widget").toggleClass("is-active");
    },
    backToTop() {
      // window.scrollTo({ top: 0, behavior: "smooth" });
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: 0
          },
          800
        );
    }
  }
});
