if ($(".js-video-modal").length) {
  let Videos = (function() {
    const $ = jQuery;
    let players = [];
    let queue = [];

    // Constructor
    function Videos() {}

    // METHODS
    // Add elements to queue
    Videos.prototype.add = function($video) {
      queue.push($video);
    };

    // Load YT API
    Videos.prototype.loadApi = function() {
      $.getScript("//www.youtube.com/iframe_api", function() {
        window.onYouTubeIframeAPIReady = function() {
          queue.forEach(function($video) {
            const player = new YT.Player($video.get(0), {
              width: "560",
              height: "315",
              videoId: $video.data("video-id"),
              events: {
                onReady: onPlayerReady
              }
            });

            function onPlayerReady(event) {
              $(".js-video-modal-close").click(function() {
                player.pauseVideo();
              });
              $('#video-modal-' + $video.data("video-id") ).on( 'play', function() {
                console.log('play');
                player.playVideo();
              });
              
            }

            players.push(player);
          });
        };
      });
    };

    return Videos;
  })();

  const videos = new Videos();
  $(".embed-player").each(function() {
    videos.add($(this));
  });
  videos.loadApi();
}
