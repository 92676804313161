import "slick-carousel";
import "slick-carousel/slick/slick.css";

$(".js-slick-carousel").slick({
  lazyLoad: "progressive",
  mobileFirst: true,
  rows: 0,
  swipeToSlide: true,
  touchMove: false
});

if ($(".js-slick-video").length) {
  const slickVideoSlider = $(".js-slick-video");
  const slickVideoNavSlider = $(".js-slick-video-nav");
  let players = [];

  let Videos = (function() {
    const $ = jQuery;
    let queue = [];

    // Constructor
    function Videos() {}

    // METHODS
    // Add elements to queue
    Videos.prototype.add = function($video) {
      queue.push($video);
    };

    // Load YT API
    Videos.prototype.loadApi = function() {
      $.getScript("https://www.youtube.com/iframe_api", function() {
        window.onYouTubeIframeAPIReady = function() {
          queue.forEach(function($video) {
            const player = new YT.Player($video.get(0), {
              width: "1200",
              height: "675",
              videoId: $video.data("video-id"),
              events: {
                onReady: onPlayerReady
              }
            });

            function onPlayerReady(event) {
              const playButton = $(
                "[data-video-target='" + $video.data("video-id") + "']"
              );
              playButton.click(function() {
                player.playVideo();
              });
            }

            players.push(player);
          });
        };
      });
    };

    return Videos;
  })();

  const videos = new Videos();
  $(".embed-player").each(function() {
    videos.add($(this));
  });
  videos.loadApi();

  slickVideoNavSlider.slick({
    asNavFor: ".js-slick-video",
    arrows: false,
    dots: true,
    touchMove: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false
        }
      }
    ]
  });

  slickVideoSlider.slick({
    asNavFor: ".js-slick-video-nav",
    touchMove: false,
    rows: 0
  });

  slickVideoSlider.on("beforeChange", function(event, slick) {
    slick = $(slick.$slider);
    const videoPlay = slick.find(".index__video__play");
    const videoCover = slick.find(".index__video__cover");

    videoPlay.removeClass("hidden");
    videoCover.removeClass("hidden");

    for (let i = 0; i < players.length; i++) {
      players[i].stopVideo();
    }
  });

  $(".js-video-play").click(function() {
    const targetVideoCover = $("#" + $(this).data("video-cover-target"));

    $(this).addClass("hidden");
    targetVideoCover.addClass("hidden");
  });
}

if ($(".js-slick-products-list").length) {
  const slickProductsSlider = $(".js-slick-products-list");
  const initProductsSlider = () => {
    slickProductsSlider.slick({
      rows: 0,
      slidesToShow: 4,
      swipeToSlide: true,
      touchMove: false
    });
  };

  if ($(window).width() >= 1280) {
    initProductsSlider();
  }
  $(window).resize(function() {
    if (!slickProductsSlider.hasClass("slick-initialized") && $(window).width() >= 1280) {
      initProductsSlider();
    }
    if (slickProductsSlider.hasClass("slick-initialized") && $(window).width() < 1280) {
      slickProductsSlider.slick("unslick");
    }
  });
}

if ($(".js-slick-product").length) {
  // Product slider paging on mobile
  $(".js-slick-product").on("init reInit afterChange", function(
    event,
    slick,
    currentSlide
  ) {
    let index = (currentSlide ? currentSlide : 0) + 1;
    $(".js-slick-product-paging").text(index + "/" + slick.slideCount);
  });

  // Product slider
  $(".js-slick-product").slick({
    arrows: false,
    asNavFor: ".js-slick-product-nav",
    lazyLoad: "progressive",
    mobileFirst: true,
    rows: 0,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          vertical: true,
          verticalSwiping: true
        }
      }
    ]
  });
}
