import Vue from "vue/dist/vue.esm";

if ($("#users-form").length > 0) {
  new Vue({
    el: "#users-form",
    data: () => {
      return {
        activeTab: "log-in",
        areInputsFilled: false,
        targetPassword: ""
      };
    },
    mounted() {
      GreenCome.retrigger("#users-form");
      this.checkActiveTab();
    },
    methods: {
      revealPassword() {
        const $targetInput = event.target.previousElementSibling;
        if ($targetInput.type === "password") {
          $targetInput.type = "text";
        } else {
          $targetInput.type = "password";
        }
      },
      checkActiveTab() {
        if ($(".users-registrations.create").length > 0) {
          this.activeTab = "sign-up";
        }
      }
    }
  });
}
