function updateTagsContainer() {
  let totalTagsWidth = 0;

  const $tagContainer = $(".js-tags-container"),
    $loadMoreBtn = $(".js-tags-load-more");

  $tagContainer.children().each(function() {
    totalTagsWidth += $(this).outerWidth();
  });

  if (totalTagsWidth <= $tagContainer.outerWidth()) {
    $loadMoreBtn.addClass("hidden");
    $tagContainer.addClass("is-expanded");
  } else {
    $loadMoreBtn.removeClass("hidden");
    $tagContainer.removeClass("is-expanded");
  }
}

$(document).ready(function() {
  updateTagsContainer();
});

$(window).resize(function() {
  updateTagsContainer();
});

$(document).on("click", ".js-tags-hide-more", () => {
  $(".js-tags-wrapper").toggleClass("is-expanded");
});

$(document).on("click", ".js-tags-load-more", () => {
  $(".js-tags-wrapper").toggleClass("is-expanded");
});