export const animateCSS = (
  node: HTMLElement,
  animation: string,
  prefix = 'animate__',
  method: 'add' | 'remove' = 'add'
): void => {
  const animationName = `${prefix}${animation}`;

  if (method === 'remove') {
    $(node).removeClass(`${prefix}animate__animated ${animationName}`);
    return;
  }

  $(node).addClass(`${prefix}animate__animated ${animationName}`);
};
