import Vue from "vue/dist/vue.esm";

import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareLine from "vue-goodshare/src/providers/Line.vue";

import bodyScrollLock from "../modules/scroll-lock";

if ($(".products.show").length > 0) {

  const tabsMenu = $("#content-tabs");
  const tabsMenuHeight = tabsMenu.outerHeight() + 80;

  new Vue({
    el: "#product-cover",
    components: {
      VueGoodshareFacebook,
      VueGoodshareLine
    },
    data: () => {
      return {
        isListLoadMore: false,
        isTagsExpanded: false
      };
    },
    mounted() {
      GreenCome.retrigger("#product-cover");
    },
    methods: {
      showSNSWidget() {
        $("#sns-widget").fadeToggle();
        $("#sns-widget").toggleClass("is-active");
        $(".js-toggle-sns-widget").toggleClass("is-active");
        console.log('click')
        if ($(window).width() < 1280) {
          bodyScrollLock.toggle();
        }
      },
      toggleSharePanel() {
        $(".product__actions #share-panel").fadeToggle();
        $(".js-toggle-share-panel").toggleClass("is-active");
        if ($(window).width() < 1280) {
          bodyScrollLock.toggle();
        }
      }
    }
  });

  new Vue({
    el: "#product-content",
    data: () => {
      return {
        isListLoadMore: false,
        activeTab: "tab-product-intro"
      };
    },
    mounted() {
      window.addEventListener("scroll", this.handleTabsScroll);
      GreenCome.retrigger("#product-content", 5000);
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleTabsScroll);
    },
    methods: {
      handleTabsScroll() {
        const currentPosition = window.pageYOffset + tabsMenuHeight;
        const contentPosition = $(".product__content__body").offset().top;
        const tabsMenu = $("#content-tabs");
        let currentSection;

        if (currentPosition > contentPosition) {
          $(".product__content__body")
            .children()
            .each(function() {
              const targetTop = $(this).offset().top - 60;
              const targetHeight = $(this).outerHeight();
              if (
                currentPosition > targetTop &&
                currentPosition <= targetTop + targetHeight
              ) {
                currentSection = $(this).attr("id");
              }
            });
          this.activeTab = currentSection;
          if (currentSection) {
            tabsMenu.scrollLeft(
              $("#button-" + currentSection).position().left +
                tabsMenu.scrollLeft()
            );
          }
        } else {
          this.activeTab = "tab-product-intro";
        }
      },
      scrollToSection(target) {
        const offsetTop = $("#" + target).offset().top - tabsMenuHeight;
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: offsetTop - 30
            },
            800
          );
      }
    }
  });

  new Vue({
    el: "#product-footer",
    data: () => {
      return {
        activeTab: "viewed-products"
      };
    },
    mounted() {
      GreenCome.retrigger("#product-footer");
    }
  });

  new Vue({
    el: "#product-you-may-like",
    data: () => {
      return {
        activeTab: "recommend-products"
      };
    },
    mounted() {
      GreenCome.retrigger("#product-you-may-like");
    }
  });
}
