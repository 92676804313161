import * as ClipboardJS from "clipboard";

const selector = "[data-clipboard-text]";

function initClipboard() {
  $(selector).each(() => {
    const clipboard = new ClipboardJS(selector);

    clipboard.on("success", e => {
      e.clearSelection();
      GreenCome.showToast("已複製分享連結");
    });
  });
}

initClipboard();
