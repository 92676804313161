const $inputTabs = $(".js-input-tab");
const $inputTabcontents = $(".js-input-tabcontent");

const $inputConditionalTabcontents = $(".js-input-conditional-tabcontent");

const $inputButtons = $(".js-button-radio");

$inputTabcontents.hide();
$inputConditionalTabcontents.hide();

$inputTabs.each(function() {
  const radio = $(this);
  if (radio.prop("checked")) {
    $(
      "[data-value=" + radio.val() + '][data-name="' + radio.attr("name") + '"]'
    ).show();
    radio.parents(".js-button-radio").addClass("is-active");
  }
  radio.on("change", function() {
    $('[data-name="' + radio.attr("name") + '"]').hide();
    if(radio.attr('checked') || radio.prop('checked')) {
      $(
        "[data-value=" + radio.val() + '][data-name="' + radio.attr("name") + '"]'
      ).show();
    }
  });
});

$inputButtons.on("click", function() {
  const radio = $(this).find("input");
  $(this)
    .siblings()
    .removeClass("is-active");
  $(this)
    .siblings()
    .each(function() {
      $(this)
        .find("input[type=radio]")
        .prop("checked", false)
        .attr("checked", false);
    });
  $(this).addClass("is-active");
  radio.prop("checked", true).attr("checked", "checked");
  radio.trigger("change");
});
