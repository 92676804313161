import Vue from "vue/dist/vue.esm";

const windowWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

if ($("body.products-list").length > 0) {
  new Vue({
    el: "#products-list",
    data: () => {
      return {
        layout: "col",
        sorting: "custom", //自訂排序
        sortingDropdownExpanded: false,
        quantitySelectorActive: false
      };
    },
    mounted() {
      GreenCome.retrigger("#products-list");

      if (!sessionStorage.productLayout) {
        if (windowWidth > 480) {
          this.selectLayout("grid");
        }
      } else {
        console.log(this.layout);
        if (sessionStorage.productLayout == "col" && windowWidth > 850) {
          this.selectLayout("grid");
        } else {
          this.layout = sessionStorage.productLayout;
        }
      }
    },
    methods: {
      selectLayout(newLayout) {
        this.layout = newLayout;
        sessionStorage.productLayout = newLayout;
      }
    }
  });
}
